import Image from "../components/image"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Meta from "../components/meta"

export const query = graphql`
  query($pathSlug: String!) {
    mdx(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        description
        category
        date
        path
        banner {
          base
        }
      }
      body
    }
  }
`
const Post = ({
  data: {
    mdx: {
      frontmatter: { title, description, path, base },
      body
    }
  }
}) => {
  return (
    <>
      <Meta
        title={title}
        description={description}
        path={path}
        banner={base}
        isBlogPost
      />
      <Layout>
        <h1>{title}</h1>
        <MDXRenderer>{body}</MDXRenderer>
      </Layout>
    </>
  )
}

export default Post

// <SEO
//   title={page.frontmatter.title}
//   description={page.excerpt}
//   path={page.frontmatter.slug}
//   cover={page.frontmatter.cover && page.frontmatter.cover.publicURL}
// />

import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import useSiteMetadata from "../hooks/use-site-config"

interface Props {
  title: string
  description: string
  banner: string
  path: string
  isBlogPost: boolean
}

const Meta: FC<Props> = props => {
  const { isBlogPost } = props
  const {
    siteTitle,
    siteUrl,
    siteDescription,
    siteCover,
    defaultLang
  } = useSiteMetadata()
  const title = props.title
    ? `${props.title} | ${siteTitle}`
    : `${siteTitle} - ${siteDescription}`
  const formatedSiteUrl = siteUrl.endsWith("/")
    ? siteUrl.substring(0, siteUrl.length - 1)
    : siteUrl
  const imagePath = props.banner || withPrefix(siteCover)
  const image = `${formatedSiteUrl}${imagePath}`
  const description = props.description || siteDescription
  const path = props.path

  return (
    <Helmet title={title}>
      {/* General tags */}
      <html lang={defaultLang} />
      <meta name="description" content={description} />
      <link rel="canonical" href={formatedSiteUrl + withPrefix(path)} />
      <meta name="theme-color" content="#121314" />
      <meta name="image" content={image} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={formatedSiteUrl + withPrefix(path)} />
      <meta property="og:type" content={isBlogPost ? "article" : "website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="en_GB" />

      {/* twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={twitterUsername} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default Meta

// <meta charSet="utf-8" />
// <meta http-equiv="x-ua-compatible" content="ie=edge" />
// <meta
//   name="viewport"
//   content="width=device-width, initial-scale=1, shrink-to-fit=no"
// />
